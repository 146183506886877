import React, { useMemo } from "react";
import { TreeItem } from "../@types/types";
import { Branch } from "./components/Branch";
import { Leaf } from "./components/Leaf";

type Props = {
  items: TreeItem[];
  selectedItems: string[];
  renderItem: (item: TreeItem) => React.ReactNode;
  showSelectAll?: boolean;
  selectAllLabel?: string;
  allSelected?: boolean;
  selectAll?: () => void;
  deSelectAll?: () => void;
};

const B2bTreeView = ({
  items,
  selectedItems,
  renderItem,
  showSelectAll = false,
  selectAllLabel = "Select all",
  allSelected = false,
  selectAll = () => {},
  deSelectAll = () => {},
}: Props) => {
  const renderTreeItems = useMemo(() => {
    return items.map((item) => renderItem(item));
  }, [items, selectedItems]);
  const handleSelectAll = () => {
    if (allSelected) {
      deSelectAll();
    } else {
      selectAll();
    }
  };
  return (
    <>
      {showSelectAll && (
        <Leaf
          id="all"
          label={selectAllLabel}
          onSelectItem={handleSelectAll}
          isSelected={allSelected}
        />
      )}
      <div>{renderTreeItems}</div>
    </>
  );
};

export { Branch, Leaf, B2bTreeView };
