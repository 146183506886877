import { Card, Grid, Checkbox, Button, IconChevronDown, IconChevronRight, Paragraph } from "@purpurds/purpur";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { itemIsLeaf, TreeItem } from "../../@types/types";

interface BranchProps {
  id:string;
  label: string;
  disabled?: boolean;
  childItems: TreeItem[];
  renderItem: (item: TreeItem) => React.ReactNode;
  onSelectBranch: (branchId: string) => void;
}

const leafs = (children: TreeItem[], items: { selected: boolean; id: string }[]) => {
  const allChildren: { selected: boolean; id: string }[] = items;
  children.forEach((child) => {
    if (itemIsLeaf(child)) {
      allChildren.push({ selected: !!child.isSelected, id: child.id });
    } else {
      leafs(child.children, allChildren);
    }
  });
  return allChildren;
};

export const Branch = ({ id,label, childItems, disabled, renderItem, onSelectBranch  }: BranchProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const allLeafs = useMemo(() => {
    return leafs(childItems, []);
  }, [childItems]);

  const checkState = () => {
    const isSelected = allLeafs.every((child) => child.selected);
    const isPartialSelection = !isSelected && allLeafs.some((child) => child.selected);
    if (isSelected) {
      return true;
    } else if (isPartialSelection) {
      return "indeterminate";
    }
    return false;
  };
  const handleSelect = () => {
    onSelectBranch(id)
  }

  const handleExpandItem = () => {
    setIsOpen(!isOpen);
  };

  if(childItems.length === 0) {
    return null;
  }

  return (
    <div>
      <TreeItemContainer>
        <Grid noRowGap>
          <Grid.Item colSpanLg={12}>
            <TreeItemRow>
              <Button variant="tertiary-purple" onClick={handleExpandItem} iconOnly>
                {isOpen ? <IconChevronDown size="xs" /> : <IconChevronRight size="xs" />}
              </Button>
                {!disabled ? <Checkbox
                  id={label}
                  label={label}
                  checked={checkState()}
                  onChange={handleSelect}
                /> : <Paragraph>{label}</Paragraph>}
            </TreeItemRow>
          </Grid.Item>
          <Grid.Item colSpanLg={12}>{isOpen && childItems.map(renderItem)}</Grid.Item>
        </Grid>
      </TreeItemContainer>
    </div>
  );
};
const TreeItemContainer = styled(Card)`
  margin-left: var(--purpur-spacing-200);
`;

const TreeItemRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
