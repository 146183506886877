import { Card, Grid, Checkbox, Paragraph } from "@purpurds/purpur";
import React from "react";
import styled from "styled-components";

interface LeafProps {
  id: string;
  label: string;
  isSelected: boolean;
  isHidden?: boolean;
  disabled?: boolean;
  onSelectItem: (ids: string) => void;
}
export const Leaf = ({ id, label, isSelected, isHidden, disabled, onSelectItem }: LeafProps) => {
  const handleSelectItem = () => {
    onSelectItem(id);
  };

  if(isHidden) {
    return;
  }
  return (
    <div>
      <TreeItemContainer>
        <Grid>
          <Grid.Item colSpanLg={12}>
            <TreeItemRow>
              {!disabled ? <Checkbox id={label} label={label} checked={isSelected} onChange={handleSelectItem} /> : <Paragraph>{label}</Paragraph>}
            </TreeItemRow>
          </Grid.Item>
        </Grid>
      </TreeItemContainer>
    </div>
  );
};
const TreeItemContainer = styled(Card)`
  margin-left: var(--purpur-spacing-200);
`;

const TreeItemRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding-left: 52px;
`;
