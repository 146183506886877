export type TreeItem  = TreeBranch | TreeLeaf;

export interface TreeBranch {
  id: string;
  label: string;
  groupByKey?: string;
  children: TreeItem[];
  disabled?: boolean;
}

export interface TreeLeaf {
  id: string;
  label: string;
  disabled?: boolean;
  isHidden?: boolean;
  isSelected: boolean;
}

export const itemIsBranch = (item: TreeItem): item is TreeBranch => {
  return (item as TreeBranch).children !== undefined;
}

export const itemIsLeaf = (item: TreeItem): item is TreeLeaf => {
  return (item as TreeLeaf).isSelected !== undefined;
}


